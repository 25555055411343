
<template>
  <div>
    <div class="search-bar">
      <search
        @inputChange="searchInput"
        @btnSubmit="searchSubmit"
        :showBtn="true"
        :label="$t('voucher.code')"
      />
    </div>
    <div v-if="vouchers" class="custom-table">
      <table-actions
        :actions="['addBtn', 'search', 'perPage']"
        :addBtnText="$t('record.send')"
        addBtnLink="/records/send/"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="vouchers"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:code="data">
          <router-link :to="`/records/send/${data.value.id}`">
            {{ data.value.code }}
          </router-link>
        </template>
        <template v-slot:client="data">
          <div class="voucher-client" v-if="data.value.order && data.value.order.client">
            <strong>{{ data.value.order.client.givenName ? data.value.order.client.givenName : '' }} {{ data.value.order.client.familyName ? data.value.order.client.familyName : '' }}</strong>
            <div>{{ data.value.order.client.email }}</div>
            <div>{{ data.value.order.client.telephone }}</div>
          </div>
        </template>
        <template v-slot:date="data">{{ $helper.formatDate(data.value.memoSendAt) }}</template>
        <template v-slot:memoUrl="data">{{ data.value.memoUrl }}</template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import Search from "@/components/Inputs/Search.vue";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    Search,
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      vouchers: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("record.code"),
          field: "code",
          sortable: false,
        },
        {
          label: this.$t("record.client"),
          field: "client",
          sortable: false,
        },
        {
          label: this.$t("record.date"),
          field: "date",
          sortable: false,
        },
        {
          label: this.$t("record.record"),
          field: "memoUrl",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.loadRecords();
  },
  methods: {
    searchSubmit(value) {
      this.search = value
      this.searchVoucher()
    },
    searchVoucher() {
      this.$Vouchers.getCollection({ params: { code: this.search.replace(/[^A-Za-z0-9]/g, ''), "order[order.createdAt]": "desc" }}, 'voucher_list').then((response) => {
        if(response.data['hydra:member'] && response.data['hydra:member'].length) {
          this.$router.push(`/records/send/${response.data['hydra:member'][0].id}`)
        }
      });
    },
    loadRecords() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        "order[order.createdAt]": "desc",
        status: ["ACTIVATED", "USED", "PAID"],
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Vouchers.getCollection({ params }, 'voucher_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.vouchers = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadRecords();
    },
    error() {
      this.$emit("clearAction");
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadRecords();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadRecords();
    },
    onPageChange() {
      this.loadRecords();
    },
  },
};
</script>
